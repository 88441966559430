import '../styles/globals.css'
import { DefaultSeo } from 'next-seo'
import { useRouter } from 'next/router'
import { setCookies } from '../utils/useCookies'

function MyApp({ Component, pageProps }) {
    const router = useRouter()

    if (router.asPath.includes('?') && router.asPath.includes('utm_source')) {
        const utmSource = router.asPath.split('utm_source=')[1]?.split('&')[0]
        const utmMedium = router.asPath.split('utm_medium=')[1]?.split('&')[0]
        const utmCampaign = router.asPath.split('utm_campaign=')[1]?.split('&')[0]
        const utmContent = router.asPath.split('utm_content=')[1]?.split('&')[0]
        const utmTerm = router.asPath.split('utm_term=')[1]?.split('&')[0]
        const utm = {
            utm_source: utmSource,
            utm_medium: utmMedium,
            utm_campaign: utmCampaign,
            utm_content: utmContent,
            utm_term: utmTerm,
        }
        setCookies('@Prime:UTM', JSON.stringify(utm), 60 * 60 * 24 * 30)
    }

    if (router.asPath.includes('?') && router.asPath.includes('gclid=')) {
        const gclid = router.asPath.split('gclid=')[1].split('&')[0]
        setCookies('@Prime:gclid', gclid, 60 * 60 * 24 * 30)
    }

    if (router.asPath.includes('?') && router.asPath.includes('fbclid=')) {
        const fbclid = router.asPath.split('fbclid=')[1].split('&')[0]
        setCookies('@Prime:fbclid', fbclid, 60 * 60 * 24 * 30)
    }

    if (router.asPath.includes('?') && router.asPath.includes('fbp=')) {
        const fbp = router.asPath.split('fbp=')[1].split('&')[0]
        setCookies('@Prime:fbp', fbp, 60 * 60 * 24 * 30)
    }

    if (router.asPath.includes('?') && router.asPath.includes('fbc=')) {
        const fbc = router.asPath.split('fbc=')[1].split('&')[0]
        setCookies('@Prime:fbc', fbc, 60 * 60 * 24 * 30)
    }

    return (
        <>
            <DefaultSeo
                title="Passagens Aéreas - Descontos HOJE!"
                description="Chega de ficar procurando em qualquer lugar. ✈️ As melhores passagens aéreas baratas você encontra na Fly Prime. Aproveite nossas ofertas e promoções!"
                canonical="https://flyprime.com.br/"
                openGraph={{
                    type: 'website',
                    locale: 'pt_BR',
                    url: 'https://www.flyprime.com.br/',
                    siteName: 'FlyPrime Viagens',
                }}
                twitter={{
                    handle: '@handle',
                    site: '@site',
                    cardType: 'summary_large_image',
                }}
            />
            <Component {...pageProps} />
        </>
    )
}

export default MyApp
